<template>
    <on-click-outside :do="close">
        <div class="tag-select" ref="tagSelect" :class="{ 'is-active': isOpen }">
            <button ref="button" @click.prevent="switchPopper" type="button" class="search-select-input">
                <font-awesome-icon class="text-black text-base" :icon="['fas', 'plus']"></font-awesome-icon>
            </button>
            <div ref="panel" v-show="isOpen" class="tag-select-panel w-full h-96 overflow-x-hidden overflow-y-auto">
                <div class="text-lg p-1">
                    Выбрать тег
                </div>
                <div v-if="tagColumns.game_type_id.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Категория</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.game_type_id.value" type="checkbox"  id="game_type_id"/>
                        <label for="game_type_id"></label>
                    </div>
                </div>
                <div v-if="tagColumns.game_subtype_id.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Подкатегория</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.game_subtype_id.value" type="checkbox"  id="game_subtype_id"/>
                        <label for="game_subtype_id"></label>
                    </div>
                </div>
                <div v-if="tagColumns.game_topic_id.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Тематика</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.game_topic_id.value" type="checkbox"  id="game_topic_id"/>
                        <label for="game_topic_id"></label>
                    </div>
                </div>
                <div v-if="tagColumns.main_type.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Категория игры</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.main_type.value" type="checkbox"  id="main_type"/>
                        <label for="main_type"></label>
                    </div>
                </div>
                <div v-if="tagColumns.sub_type.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Тип игры</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.sub_type.value" type="checkbox"  id="sub_type"/>
                        <label for="sub_type"></label>
                    </div>
                </div>
                <div v-if="tagColumns.topic.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Тематика</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.topic.value" type="checkbox"  id="topic"/>
                        <label for="topic"></label>
                    </div>
                </div>
                <div v-if="tagColumns.is_special.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Спец. игра</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.is_special.value" type="checkbox"  id="is_special"/>
                        <label for="is_special"></label>
                    </div>
                </div>
                <div v-if="tagColumns.is_adaptive.required" class="flex p-1">
                  <label class="font-medium text-brand justify-start">Адаптация</label>
                  <div class="ml-auto brand-checkbox">
                    <input class="" v-model="tags.is_adaptive.value" type="checkbox"  id="is_adaptive"/>
                    <label for="is_adaptive"></label>
                  </div>
                </div>
                <div v-if="tagColumns.slide_type_id.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Формат вопроса</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.slide_type_id.value" type="checkbox"  id="slide_type"/>
                        <label for="slide_type"></label>
                    </div>
                </div>
                <div v-if="tagColumns.tour_id.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Номер пакета</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.pack_number.value" type="checkbox"  id="pack_number"/>
                        <label for="pack_number"></label>
                    </div>
                </div>
                <div class="w-full border-b-2 border-grey-light my-2"></div>
                <div v-if="tagColumns.tour_id.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Стандарт вопроса</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.tour_id.value" type="checkbox"  id="tour"/>
                        <label for="tour"></label>
                    </div>
                </div>
                <div v-if="tagColumns.theme.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Текст</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.theme.value" type="checkbox" id="theme"/>
                        <label for="theme"></label>
                    </div>
                </div>
                <div v-if="tagColumns.picture.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Картинки</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.picture.value" type="checkbox" id="picture"/>
                        <label for="picture"></label>
                    </div>
                </div>
                <div v-if="tagColumns.blitz.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Блиц</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.blitz.value" type="checkbox" id="blitz"/>
                        <label for="blitz"></label>
                    </div>
                </div>
                <div v-if="tagColumns.company_type.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Компании</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.company_type.value" type="checkbox" id="company_type"/>
                        <label for="company_type"></label>
                    </div>
                </div>
                <div v-if="tagColumns.company_name.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Имя компании</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.company_name.value" type="checkbox" id="company_name"/>
                        <label for="company_name"></label>
                    </div>
                </div>
                <div v-if="tagColumns.complexity.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Сложность</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.complexity.value" type="checkbox" id="complexity"/>
                        <label for="complexity"></label>
                    </div>
                </div>
                <div v-if="tagColumns.question_type.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Тип вопроса</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.question_type.value" type="checkbox" id="question_type"/>
                        <label for="question_type"></label>
                    </div>
                </div>
                <div v-if="tagColumns.includedRegions.required" class="flex p-1">
                    <label v-if="tagColumns.excludedRegions.required" class="font-medium text-brand justify-start">Регион +</label>
                    <label v-else class="font-medium text-brand justify-start">Регион</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.includedRegions.value" type="checkbox" id="region"/>
                        <label for="region"></label>
                    </div>
                </div>
                <div v-if="tagColumns.excludedRegions.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Регион -</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.excludedRegions.value" type="checkbox" id="excluded_region"/>
                        <label for="excluded_region"></label>
                    </div>
                </div>
                <div v-if="tagColumns.city_search_type.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Тип поиска по городу</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.city_search_type.value" type="checkbox"  id="city_search_type"/>
                        <label for="city_search_type"></label>
                    </div>
                </div>
                <div v-if="tagColumns.city.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Город</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.city.value" type="checkbox"  id="city"/>
                        <label for="city"></label>
                    </div>
                </div>
                <div v-if="tagColumns.show_additional" class="w-full border-b-2 border-grey-light my-2"></div>
                <div v-if="tagColumns.musical_answer_type.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Музыка</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.musical_answer_type.value" type="checkbox" id="musical_answer"/>
                        <label for="musical_answer"></label>
                    </div>
                </div>
                <div v-if="tagColumns.direction.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Стиль</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.direction.value" type="checkbox" id="direction"/>
                        <label for="direction"></label>
                    </div>
                </div>
                <div v-if="tagColumns.music_region.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Локал</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.music_region.value" type="checkbox" id="music_region"/>
                        <label for="music_region"></label>
                    </div>
                </div>
                <div v-if="tagColumns.hit.required" class="flex p-1">
                    <label class="font-medium text-brand justify-start">Хит</label>
                    <div class="ml-auto brand-checkbox">
                        <input class="" v-model="tags.hit.value" type="checkbox" id="hit"/>
                        <label for="hit"></label>
                    </div>
                </div>
                <div v-if="tagColumns.source.required" class="flex p-1">
                  <label class="font-medium text-brand justify-start">Источник</label>
                  <div class="ml-auto brand-checkbox">
                    <input class="" v-model="tags.source.value" type="checkbox" id="source"/>
                    <label for="source"></label>
                  </div>
                </div>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import OnClickOutside from './OnClickOutside.vue'
import Popper from 'popper.js'

export default {
  name: 'TagSelectPanel',
  components: {
    OnClickOutside,
  },
  inject: ['tagColumns'],
  data () {
    return {
      isOpen: false,
      tags: {

      },
    }
  },
  watch: {
    tags: {
      handler (val) {
        this.$emit('tagColumns', val)
        this.$nextTick(() => {
          this.$refs['tagSelect'].scrollIntoView({
            block: 'nearest',
          })
        })
      },
      deep: true,
    },
  },
  beforeDestroy () {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  computed: {},
  created () {
    this.tags = this.tagColumns
  },
  methods: {
    switchPopper () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.panel, {
          placement: 'bottom-end',
          positionFixed: true,
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    select (option) {
      this.close()
    },
  },
}
</script>

<style scoped>
    .tag-select {
        position: relative;
    }
    .tag-select-panel {
        width: 16rem;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        position: absolute;
        right: 0;
        left: 0;
        background-color: #fff;
        padding: 0.5rem;
        border-radius: 0.25rem;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.6);
        z-index: 50;
    }
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #dae1e7;
      border-radius: .25rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #6a6384;
      border-radius: .25rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b4b0c1;
    }
</style>
